@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;700&display=swap');

body {
    margin: 0;
    padding-bottom: 16px;
}

.container {
    font-family: 'Readex Pro', sans-serif;
    max-width: 850px;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.flex-container {
    display: flex;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

a.top-link:visited {
    color: black;
}

.top-link-small > h2 {
    font-size: 20px;
    font-weight: normal;
}

.top-link-small:not(:last-child) > h2 {
    padding-right: 12px;
}

a,
a:visited,
.clickable {
    text-decoration: none;
    color: #454545;
}

a:visited {
    text-decoration-color: darkgray;
}

a {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: darkgray;
    text-underline-offset: 3px;
}

.clickable {
    cursor: pointer;
    transition: color 0.3s ease;
}

a:hover,
.top-link > *:hover,
.clickable:hover {
    color: #656565;
    transition: color 0.3s ease;
}

.top-link > * {
    transition: color 0.3s ease;
}

div.main-content {
    line-height: 150%;
}

.list-relaxed > li:not(:last-child) {
    padding-bottom: 12px;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-bottom: 4px;
    margin-left: 8px;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transition: transform 0.3s ease;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transition: transform 0.3s ease;
}

.rounded-image {
    border-radius: 5px;
}

.home-image {
    margin-top: 24px;
    margin-left: 12px;
    height: 400px;
}

.message {
    background-color: lightgray;
    border-radius: 10px;
    border: 3px solid darkgray;
    padding: 5px 10px;
    color: #525252;
    line-height: 140%;
}

.bordered-gradient {
    border-bottom: 1px solid black;
    background-image: linear-gradient(white, #ececec);
}

.flex-container-wrapping {
    display: flex;
}

.hamburger-menu {
    display: none;
    cursor: pointer;
    padding: 10px;
}

.nav-links {
    display: flex;
    align-items: baseline;
}

@media only screen and (max-width: 800px) {
    .flex-container-wrapping {
        flex-wrap: wrap;
    }

    .flex-photo-right {
        margin-left: auto;
        margin-right: auto;
    }

    .hamburger-menu {
        display: block;
    }

    /* Modify header positioning */
    .top-nav {
        position: relative; /* Create positioning context */
    }

    .nav-links {
        display: none;
        position: absolute; /* Position absolutely within header */
        top: 110%; /* Place right below header */
        left: 0;
        right: 0;
        background: #e7e7e7; /* Add background to cover content */
        z-index: 100; /* Place above other content */
        box-shadow: 1px 1px 5px 5px lightgray; /* Optional: adds depth */
        padding: 10px 16px; /* Match container padding */
        flex-direction: column;
    }

    .nav-links.open {
        display: flex;
    }
}

@media only screen and (min-width: 800px) {
    .flex-photo-right {
        margin-left: 12px;
    }

    .top-link {
        padding: 0 4px;
    }
}

.flex-text-left {
    margin: 0;
}

ul.spaced > li:not(:last-child) {
    padding-bottom: 12px;
}

.collapsible-section {
    transition: all 0.5s ease-in-out;
}
